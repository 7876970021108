<template>
  <div class="hotel-information">
    <h3 class="sub-title">Furuse & AATS Foundation Award - USD 1,000</h3>
    <div class="container">
      <ul class="disc">
        <li>Awards with an honorarium of <span class="text-bold"><u>USD 1,000</u></span> each will be given to the best paper in the field of <u><span class="text-bold">thoracic surgery (<span class="text-danger">AATS Foundation Award</span>)</span></u> and <u><span class="text-bold">cardiovascular surgery (<span class="text-danger">Furuse Award</span>) </span></u> respectively.</li>
        <li>Three finalists in each category will be selected from the submitted abstracts. They are requested to present their work at the "Furuse &amp; AATS Foundation Awards" session after which the judges will determine the awardees.</li>
        <li>Requirements for application
          <ul class="dash">
            <li>The applicant should be the first author and presenter.</li>
            <li>The finalists should complete their registration by the pre-registration deadline.</li>
            <li>All finalists are requested to submit the full manuscript to the organizing committee and the Asian Cardiovascular &amp; Thoracic Annals, adhering to its submission guideline (<a href="https://mc.manuscriptcentral.com/aan" target="_blank">https://mc.manuscriptcentral.com/aan</a>).</li>
          </ul>
        </li>
        <li>All six finalists will be given complimentary invitations to the Gala Dinner where the awardees will be announced and receive the honorarium. (<i>Finalists who already purchased the Gala Dinner ticket will be reimbursed.</i>)</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.hotel-information {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: 44px;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .container {
    font-family: "PublicSans Regular";
    font-size: 16px;
    padding: 12px 20px;
    .text-danger {
      color: #dc3545;
    }
    .text-bold {
      font-weight: bold;
    }
    .disc li {
      list-style-type: disc;
      margin-left: 20px;
    }
    ul.dash > li{
      list-style: none;
      &:before {
        display: inline-block;
        content: "-";
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}
</style>
